export default class DashboardRunDto {

    Id: number;
    Name: string;
    PathId: number;
    PathTypeId: number;
    PathTypeLabel: string;
    Start: Date;
    Status: number;
    Progress: number;

    constructor(id: number, name: string, pathId: number, pathTypeId: number, pathTypeLabel: string, start: Date, status : number, progress: number) {
        this.Id = id;
        this.Name = name;
        this.PathId = pathId;
        this.PathTypeId = pathTypeId;
        this.PathTypeLabel = pathTypeLabel;
        this.Start = start;
        this.Status = status;
        this.Progress = progress;
    }
}
