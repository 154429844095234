import React, { useState, useEffect, useCallback, useMemo, useRef, useImperativeHandle, forwardRef } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, Item, SimpleItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme-react/text-area';

import ActionType from '../../classes/consts/ActionType';

import DataSourceErrorForm from './DataSourceErrorForm';

import LabelTemplate from './LabelTemplate';

import DataSourceApi from "../../api/DataSourceApi";
import CustomerAgentApi from '../../api/CustomerAgentApi';

import DataSourceCrudLogic from '../../logics/datasourcemodule/DataSourceCrudLogic';

import DataSourceTypeListDto from '../../classes/dtos/datasourcemodule/DataSourceTypeListDto';
import CustomerAgentListDto from '../../classes/dtos/customeragentmodule/CustomerAgentListDto';
import DataSourceAuthenticationDetailDto from '../../classes/dtos/datasourcemodule/DataSourceAuthenticationDetailDto';

import DataSourceFormDto from '../../classes/dtos/datasourcemodule/DataSourceFormDto';

import './DataSourceForm.scss';

interface DataSourceFormProps {
    Action: string;
    Direction: string;
    DataSourceAdded(id: number): void;
}

export interface DataSourceFormHandle {
    Open: (dataSourceId: number | null) => void;
}

const DataSourceForm: React.FC<DataSourceFormProps> = forwardRef<DataSourceFormHandle, DataSourceFormProps>(({ Action, Direction, DataSourceAdded }, ref) => {

    const position = { of: '#root' };

    const formRef = useRef<FormRef>(null);

    const [dataSourceTypes, setDataSourceTypes] = useState<DataSourceTypeListDto[] | null>(null);
    const [customerAgents, setCustomerAgents] = useState<CustomerAgentListDto[] | null>(null);
    const [withCustomerAgent, setWithCustomerAgent] = useState<boolean>(false);
    const [currentAuthenticationDetails, setCurrentAuthenticationDetails] = useState<DataSourceAuthenticationDetailDto[]>([]);

    const [dataSourceCrudLogic] = useState<DataSourceCrudLogic>(new DataSourceCrudLogic());

    const [dataSourceErrorIsOpen, setDataSourceErrorIsOpen] = useState<boolean>(false);

    const [dataSourceFormDto, setDataSourceFormDto] = useState<DataSourceFormDto>(new DataSourceFormDto(null, null, null));

    const [isOpen, setIsOpen] = useState(false);

    const [saveInProgress, setSaveInProgress] = React.useState(false);

    useImperativeHandle(ref, () => ({
        Open(dataSourceId: number | null) {
            loadAsync(dataSourceId).then(() => {
                setIsOpen(true);
                if (dataSourceId === null) {
                    setDataSourceFormDto(new DataSourceFormDto(null, "my data source", null));
                }
            });
        }
    }));

    const loadAsync = async (dataSourceId: number | null): Promise<void> => {
        let readable = Direction === "Source" ? true : null;
        let writable = Direction === "Target" ? true : null;

        let getDataSourceTypesResult = await DataSourceApi.GetDataSourceTypesByCustomerAsync(readable, writable);
        if (getDataSourceTypesResult.IsFailed()) {
            alert("Error in GetDataSourceTypesByCustomerAsync");
        }
        setDataSourceTypes(getDataSourceTypesResult.Result.DataSourceTypes);

        let allCustomerAgentsResult = await CustomerAgentApi.GetCustomerAgentsAsync();
        if (allCustomerAgentsResult.IsFailed()) {
            alert("Error in GetCustomerAgentsAsync");
        }
        setCustomerAgents(allCustomerAgentsResult.Result.CustomerAgents);

        if (dataSourceId !== null) {
            let getDataSourceResult = await DataSourceApi.GetDataSourceByIdAsync(dataSourceId);
            if (getDataSourceResult.IsFailed()) {
                alert("Error in GetDataSourceByIdAsync");
            }

            let dataSourceType = getDataSourceTypesResult.Result?.DataSourceTypes.find((e) => e.Id === getDataSourceResult.Result.Type) ?? null;
            let customerAgent = allCustomerAgentsResult.Result?.CustomerAgents.find((e) => e.Id === getDataSourceResult.Result.CustomerAgentId) ?? null;

            let data: any = new DataSourceFormDto(dataSourceType, getDataSourceResult.Result.Name, customerAgent);

            let parameters: any = getDataSourceResult.Result.Parameters;

            dataSourceType?.Authentications[0].Details.forEach((e) => {
                data[e.Name] = parameters[e.Name];
            });

            dataSourceCrudLogic.CurrentDataSourceId = dataSourceId;

            setDataSourceFormDto(data);
        }
    }

    const dataSourceTypeEditorOptions = {
        items: dataSourceTypes,
        searchEnabled: true,
        displayExpr: "Name",
        onValueChanged: (e: any) => {
            let dataSourceType = e.value as DataSourceTypeListDto;
            setWithCustomerAgent(dataSourceType !== null && dataSourceType.ConnectorTypes.length === 1 && dataSourceType.ConnectorTypes[0].Id === 3);

            if (dataSourceType !== null && dataSourceType.Authentications.length > 0) {
                setCurrentAuthenticationDetails(dataSourceType.Authentications[0].Details);
            }
            else {
                setCurrentAuthenticationDetails([]);
            }

            forceUpdate();
        }
    };

    const dataSourceNameEditorOptions = {
        valueChangeEvent: 'keyup',
        required: true
    };

    const customerAgentEditorOptions = {
        items: customerAgents,
        searchEnabled: true,
        displayExpr: "Name",
        onValueChanged: (e: any) => {
        }
    };

    const updateValues = function (name: string, value: string) {
    }

    const dataSourceCredentialEditorOptions = {
        valueChangeEvent: 'keyup',
        required: true,
        onValueChanged: (e: any) => {
            const dataField = e.component.option("name");
            updateValues(dataField, e.value.toString());
        }
    }

    const validationRules: {
        dataSourceType: ValidationRule[],
        dataSourceName: ValidationRule[],
        customerAgent: ValidationRule[],
        connectionString: ValidationRule[],
        dataSourceCredential: ValidationRule[]
    } = {
        dataSourceType: [
            { type: 'required', message: 'Data source type is required.' },
        ],
        dataSourceName: [
            { type: 'required', message: 'Data source name is required.' },
        ],
        customerAgent: [
            { type: 'required', message: 'Customer agent is required.' },
        ],
        connectionString: [
            { type: 'required', message: 'Connection string is required.' },
        ],
        dataSourceCredential: [
            { type: 'required', message: 'Credential is required.' },
        ]
    };

    const closeDialog = () => {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        };
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        setSaveInProgress(true);

        if (dataSourceFormDto.DataSourceType === null) {
            alert("DataSourceType not set");
            setSaveInProgress(false);
            return;
        }

        if (dataSourceFormDto.DataSourceName === null) {
            alert("DataSourceName not set");
            setSaveInProgress(false);
            return;
        }

        if (withCustomerAgent) {
            if (dataSourceFormDto.CustomerAgent === null) {
                alert("CustomerAgent not set");
                setSaveInProgress(false);
                return;
            }
        }

        let connectorType: number;

        if (withCustomerAgent) {
            connectorType = 3;
        }
        else {
            connectorType = 2;
        }

        let data: any = dataSourceFormDto;

        const mapParameters = new Map<string, string>()
        mapParameters.set("Type", dataSourceFormDto.DataSourceType.Authentications[0].Key);
        currentAuthenticationDetails.forEach((parameter) => {
            mapParameters.set(parameter.Name, data[parameter.Name]);
        })

        let parameters = Object.fromEntries(mapParameters);

        let createOrUpdateDataSourceResult = await dataSourceCrudLogic.CreateOrUpdateDataSourceAsync(dataSourceFormDto.DataSourceName, dataSourceFormDto.DataSourceType.Id, dataSourceFormDto.CustomerAgent?.Id ?? null, connectorType, parameters);

        if (createOrUpdateDataSourceResult.IsFailed()) {
            alert(createOrUpdateDataSourceResult.ErrorMessage);
            setSaveInProgress(false);
            return;
        }

        if (!createOrUpdateDataSourceResult.Result.Ok) {
            setDataSourceErrorIsOpen((previous) => true);
            setSaveInProgress(false);
            return;
        }

        if (dataSourceCrudLogic.CurrentDataSourceId === null) {
            alert("Update not done");
            setSaveInProgress(false);
            return;
        }

        DataSourceAdded(dataSourceCrudLogic.CurrentDataSourceId);
        setIsOpen(false);
        setSaveInProgress(false);
        return;
    };

    const close = () => {
        setIsOpen(false);
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: close
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    function getTitle() {
        switch (Action) {
            case ActionType.Create: return "Create a dataSource";
            case ActionType.Update: return "Update a dataSource";
            case ActionType.Delete: return "Delete a dataSource";
            default: return "???";
        }
    }

    const [updateTrigger, setUpdateTrigger] = useState(0);

    const forceUpdate = () => setUpdateTrigger(updateTrigger + 1);

    console.log(JSON.stringify(dataSourceFormDto));

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={closeDialog}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title={getTitle()}
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        key={updateTrigger}
                        ref={formRef}
                        formData={dataSourceFormDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="dataSourceDtoData"
                        colCount={2}
                        disabled={saveInProgress}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="DataSourceType" editorType="dxSelectBox" editorOptions={dataSourceTypeEditorOptions} validationRules={validationRules.dataSourceType}>
                                <Label text={"Data source type"} render={LabelTemplate('product')} />
                            </SimpleItem>
                            <SimpleItem dataField="DataSourceName" editorOptions={dataSourceNameEditorOptions} validationRules={validationRules.dataSourceName}>
                                <Label text={"Data source name"} render={LabelTemplate('rename')} />
                            </SimpleItem>
                            <SimpleItem dataField="CustomerAgent" editorType="dxSelectBox" editorOptions={customerAgentEditorOptions} validationRules={validationRules.customerAgent} visible={withCustomerAgent}>
                                <Label text={"Customer agent"} render={LabelTemplate('floppy')} />
                            </SimpleItem>
                            {currentAuthenticationDetails.map((e, index: number) =>
                            (
                                <SimpleItem key={`${index}`} dataField={e.Name} editorOptions={dataSourceCredentialEditorOptions} validationRules={validationRules.dataSourceCredential}>
                                    <Label text={e.Label} render={LabelTemplate("rename")} />
                                </SimpleItem>
                            ))}
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
            <LoadPanel
                position={position}
                shadingColor="rgba(0,0,0,0.4)"
                visible={saveInProgress}
            />
            <DataSourceErrorForm IsOpen={dataSourceErrorIsOpen} SetIsOpen={setDataSourceErrorIsOpen} ></DataSourceErrorForm>
        </React.Fragment>
    );
});

export default DataSourceForm;