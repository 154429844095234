class CreateOrUpdateDataSourceResult {
    Id: number;
    Ok: boolean;
    ErrorDetails: string;

    constructor(id: number, ok: boolean, errorDetails: string) {
        this.Id = id;
        this.Ok = ok;
        this.ErrorDetails = errorDetails;
    }
}

export default CreateOrUpdateDataSourceResult;
