import PathType from "../../../consts/pathmodule/PathType";
import { IJobResumeDto } from "./IJobResumeDto";

export default class PathSynchroJobResumeDto extends IJobResumeDto {
    ToAdd: number;
    ToUpdate: number;
    ToDelete: number;
    Added: number;
    Updated: number;
    Deleted: number;

    constructor(
        toAdd: number,
        toUpdate: number,
        toDelete: number,
        added: number,
        updated: number,
        deleted: number) {
        super(PathType.DataSynchronization);
        this.ToAdd = toAdd;
        this.ToUpdate = toUpdate;
        this.ToDelete = toDelete;
        this.Added = added;
        this.Updated = updated;
        this.Deleted = deleted;
    }
}