class DataSourceCharacteristicsDto {
    public ViewsLinkedToTables: boolean;
    public DeleteAvailable: boolean;

    constructor(
        viewsLinkedToTables: boolean,
        deleteAvailable: boolean
    ) {
        this.ViewsLinkedToTables = viewsLinkedToTables;
        this.DeleteAvailable = deleteAvailable;
    }
}

export default DataSourceCharacteristicsDto;
