class ScheduleDto {
    IsActive: boolean;
    Type: number;
    Interval: number;
    Time: number;

    constructor(isActive: boolean, type: number, interval: number, time: number) {
        this.IsActive = isActive;
        this.Type = type;
        this.Interval = interval;
        this.Time = (time + new Date().getTimezoneOffset()) * 60 * 1000;
    }
}

export default ScheduleDto;
