import DatabaseReferenceIdAndKeyDto from "../../../dtos/DatabaseReferenceIdAndKeyDto";

class GetDatabasesResult {
    Accepted: boolean;
    Status: number;
    LastUpdateDate: Date | null;
    Databases: DatabaseReferenceIdAndKeyDto[];

    constructor(accepted: boolean, status: number, lastUpdateDate: Date | null, databases: DatabaseReferenceIdAndKeyDto[]) {
        this.Accepted = accepted;
        this.Status = status;
        this.LastUpdateDate = lastUpdateDate;
        this.Databases = databases;
    }
}

export default GetDatabasesResult;
