import React, { useState, useEffect, useCallback } from 'react';

import { CheckBox } from 'devextreme-react/check-box';
import { SelectBox } from 'devextreme-react/select-box';
import { NumberBox } from 'devextreme-react/number-box';
import { DateBox } from 'devextreme-react/date-box';

import DataSourceCharacteristicsApi from '../../api/DataSourceCharacteristicsApi';
import ScheduleTypeApi from '../../api/ScheduleTypeApi';

import DataSourceCharacteristicsDto from '../../classes/dtos/datasourcemodule/DataSourceCharacteristicsDto';
import PathSynchroSetupDto from '../../classes/dtos/pathsynchromodule/PathSynchroSetupDto';
import ScheduleDto from '../../classes/dtos/schedulermodule/ScheduleDto';
import ScheduleTypeListDto from '../../classes/dtos/schedulermodule/ScheduleTypeListDto';

import './SynchroSetup.css';

interface SynchroSetupProps {
    PathId: number;
    DataSourceTargetId: number | null;
    PathSynchroSetup: PathSynchroSetupDto | null;
    PathSchedule: ScheduleDto | null;
    SetPathSynchroSetup(value: PathSynchroSetupDto): void;
    SetPathSchedule(value: ScheduleDto): void;
}

const SynchroSetup: React.FC<SynchroSetupProps> = ({ PathId, DataSourceTargetId, PathSynchroSetup, PathSchedule, SetPathSynchroSetup, SetPathSchedule }) => {

    const [dataSourceCharacteristics, setDataSourceCharacteristics] = useState<DataSourceCharacteristicsDto | null>(null);
    const [scheduleTypes, setScheduleTypes] = useState<ScheduleTypeListDto | null>(null);

    const dataSourceCharacteristicsLoad = useCallback(async () => {
        if (!dataSourceCharacteristics) {

            if (DataSourceTargetId === null) {
                setDataSourceCharacteristics(new DataSourceCharacteristicsDto(false, false));
                return;
            }

            DataSourceCharacteristicsApi.GetDataSourceCharacteristicsByIdAsync(DataSourceTargetId)
                .then(getDataSourceCharacteristicsResult => {
                    setDataSourceCharacteristics(getDataSourceCharacteristicsResult.Result);
                });
        }

    }, [dataSourceCharacteristics, DataSourceTargetId]);

    useEffect(() => {
        dataSourceCharacteristicsLoad()
    }, [dataSourceCharacteristicsLoad]);

    const scheduleTypesLoad = useCallback(async () => {
        if (!scheduleTypes) {

            ScheduleTypeApi.GetScheduleTypesAsync()
                .then(getScheduleTypes => {
                    setScheduleTypes(getScheduleTypes.Result);
                });
        }

    }, [scheduleTypes]);

    useEffect(() => {
        scheduleTypesLoad()
    }, [scheduleTypesLoad]);


    const onDeletionActivatedValueChanged = useCallback((e) => {
        let dto = PathSynchroSetup;
        if (dto !== null) {
            dto.DeletionActivated = e.value ?? false;
            SetPathSynchroSetup(dto);
        }
    }, []);

    const onPathScheduleIsActiveValueChanged = useCallback((e) => {
        let dto = PathSchedule;
        if (dto !== null) {
            dto.IsActive = e.value ?? false;
            SetPathSchedule(dto);
        }
    }, [])

    const onPathScheduleTypeValueChanged = useCallback((e) => {
        let dto = PathSchedule;
        if (dto !== null) {
            dto.Type = e.value ?? 0;
            SetPathSchedule(dto);
        }
    }, []);

    const onPathScheduleIntervalValueChanged = useCallback((e) => {
        let dto = PathSchedule;
        if (dto !== null) {
            dto.Interval = e.value ?? 0;
            SetPathSchedule(dto);
        }
    }, []);

    const onPathScheduleTimeValueChanged = useCallback((e) => {
        let dto = PathSchedule;
        if (dto !== null) {
            dto.Time = e.value ?? 0;
            SetPathSchedule(dto);
        }
    }, []);

    let deletionEnabled = dataSourceCharacteristics?.DeleteAvailable ?? false;

    return (
        <React.Fragment>
            <div className='BlockCard'>
                <div className='BlockHeader'>
                    <div className='BlockContent'>
                        <CheckBox disabled={!deletionEnabled} value={PathSynchroSetup?.DeletionActivated} onValueChanged={onDeletionActivatedValueChanged} />
                        <span>
                            {deletionEnabled ? ("Data deletion authorized") : ("The target doesn't manage the deletion")}
                        </span>
                    </div>
                </div>
            </div>
            <div className='BlockCard'>
                <div className='BlockHeader'>
                    <div className='BlockContent'>
                        <CheckBox value={PathSchedule?.IsActive} onValueChanged={onPathScheduleIsActiveValueChanged} />
                        <span>
                            Scheduling enabled
                        </span>
                        <SelectBox value={PathSchedule?.Type} dataSource={scheduleTypes} displayExpr="Name" valueExpr="Id" onValueChanged={onPathScheduleTypeValueChanged} />
                        <NumberBox visible={PathSchedule?.Type === 1} value={PathSchedule?.Interval} min={1} max={120} showSpinButtons={true} width={120} onValueChanged={onPathScheduleIntervalValueChanged} />
                        <DateBox visible={PathSchedule?.Type === 2} value={PathSchedule?.Time} type="time" displayFormat="HH:mm" width={120} onValueChanged={onPathScheduleTimeValueChanged} />
                        {PathSchedule?.Type === 1 ? (<span >Minutes</span>) : ""}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default SynchroSetup;