import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import GetDatabasesResult from "../classes/api/result/datastructuremodule/GetDatabasesResult";
import DatabaseReferenceIdAndKeyDto from "../classes/dtos/DatabaseReferenceIdAndKeyDto";

const DatabaseApi = {

    GetDatabasesAsync: async function (dataSourceId: number, forceUpdate: boolean): Promise<ProcessingResult<GetDatabasesResult>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unauthorized");
        }

        const response = await fetch(Settings.api_url + "/Database/GetDatabases?CustomerId=" + customerId.toString() + "&DataSourceId=" + dataSourceId.toString() + "&ForceUpdate=" + forceUpdate.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(new GetDatabasesResult(data.accepted, data.status, data.lastUpdateDate, data.databases.map((e: any) => new DatabaseReferenceIdAndKeyDto(e.id, e.key, e.name, e.type, e.readOnly, e.informativeRowCount, e.parentId))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default DatabaseApi;