import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import ScheduleTypeListDto from "../classes/dtos/schedulermodule/ScheduleTypeListDto";

const ScheduleTypeApi = {

    GetScheduleTypesAsync: async function (): Promise<ProcessingResult<ScheduleTypeListDto[]>> {
        let authSession = await AuthToken.get();

        let customerId = await UserSession.EnsureAsync(authSession.Token);

        const response = await fetch(Settings.api_url + "/ScheduleType/GetScheduleTypes?customerId=" + customerId.toString(), {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            let data = await response.json();
            return ProcessingResult.Ok(
                data.scheduleTypes.map((e: { id: number; name: string }) => new ScheduleTypeListDto(e.id, e.name))
            );
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    }
};

export default ScheduleTypeApi;