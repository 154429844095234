import Settings from "./Settings";
import { AuthToken } from "./AuthToken";

import ProcessingResult from "../framework/ProcessingResult";

import UserSession from "./UserSession";

import JobRunDto from "../classes/dtos/jobmodule/JobRunDto";
import { IJobResumeDto } from "../classes/dtos/jobmodule/IJobResumeDto";
import PathType from "../consts/pathmodule/PathType";
import PathReportingJobResumeDto from "../classes/dtos/jobmodule/PathReportingJobResumeDto";
import PathSynchroJobResumeDto from "../classes/dtos/jobmodule/PathSynchroJobResumeDto";

const JobApi = {

    GetRunListAsync: async function (begin: Date | null, end: Date | null): Promise<ProcessingResult<JobRunDto[]>> {
        let authSession = await AuthToken.get();

        var customerId = await UserSession.EnsureAsync(authSession.Token);

        if (customerId === -1) {
            return ProcessingResult.Error("Unauthorized");
        }

        const response = await fetch(Settings.api_url + "/Job/GetRunList?customerId=" + customerId.toString(), {
            method: "Get",
            mode: "cors",
            cache: "no-cache",
            credentials: 'include',
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authSession.Token
            }
        });

        if (response.status === 200) {
            var data = await response.json();
            return ProcessingResult.Ok(data.runs.map((e: any) => new JobRunDto(e.id, e.name, e.pathId, e.pathTypeId, e.pathTypeLabel, e.start, e.status, e.progress, this.ToResumeDto(e.typeId, e.resume))));
        }

        return ProcessingResult.Error("Error = " + response.status.toString());
    },

    ToResumeDto: function (pathType: number, values: any | null): IJobResumeDto | null{

        if (values === null) {
            return null;
        }

        switch (pathType) {
            case PathType.Reporting:
                return new PathReportingJobResumeDto(values.rowCount);
            case PathType.DataSynchronization:
                return new PathSynchroJobResumeDto(values.toAdd, values.toUpdate, values.toDelete, values.added, values.updated, values.deleted);
        }

        return null;
    }
};

export default JobApi;