import React from 'react';
import { DataGridTypes } from 'devextreme-react/data-grid';

import './StatusCell.scss';
import { JobStatusUnknown, JobStatusRequested, JobStatusCanceled, JobStatusError, JobStatusTerminated } from '../../classes/consts/JobStatus';

const StatusCell = (cellData: DataGridTypes.ColumnCellTemplateData) => {
    switch (cellData.value) {

        case JobStatusUnknown:
            return (<div><i className={`dx-icon dx-icon-warning`} style={{ color: '#FFA500' }}></i></div>);
        case JobStatusRequested:
            return (<div><i className={`dx-icon dx-icon-refresh`} style={{ color: '#00FF00' }}></i></div>);
        case JobStatusCanceled:
            return (<div><i className={`dx-icon dx-icon-revert`} style={{ color: '#FFA500' }}></i></div>);
        case JobStatusError:
            return (<div><i className={`dx-icon dx-icon-clear`} style={{ color: '#FF0000' }}></i></div>);
        case JobStatusTerminated:
            return (<div><i className={`dx-icon dx-icon-check`} style={{ color: '#00FF00' }}></i></div>);
        default:
            return (<div><i className={`dx-icon dx-icon-warning`} style={{ color: '#FFA500' }}></i></div>);
    }
};

export default StatusCell;
