import React, { useState, useEffect, useCallback } from 'react';
import Tabs, { Item } from 'devextreme-react/tabs';
import { useNavigate } from "react-router-dom";

import JobApi from '../../api/JobApi';
import JobRunDto from '../../classes/dtos/jobmodule/JobRunDto';
import RunGrid from '../../components/run-grid/RunGrid';

import PathType from '../../consts/pathmodule/PathType';

import { PathSynchroRoute, PathReportingRoute } from '../../app-routes';

import './runs.scss';

export default function Runs() {

    const navigate = useNavigate();

    const [currentTab, setCurrentTab] = useState<number>(0);

    const [jobRuns, setJobRuns] = useState<Array<JobRunDto> | null>(null);

    const [listToRefresh, setListToRefresh] = useState<boolean>(false);

    function onRunClick(jobId: number, pathId: number, pathTypeId: number) {
        switch (pathTypeId) {
            case PathType.DataSynchronization:
                navigate("/" + PathSynchroRoute + "/" + pathId);
                break;
            case PathType.Reporting:
                navigate("/" + PathReportingRoute + "/" + pathId);
                break;
            default:
        }    }
    
    const refresh = useCallback(() => {
        if (!jobRuns || listToRefresh) {
            JobApi.GetRunListAsync(null, null).then((allJobRuns: any) => {
                if (allJobRuns.IsFailed()) {
                    // TODO: Handle failure case (e.g., logout)
                } else {
                    setJobRuns(allJobRuns.Result);
                    setListToRefresh(false);
                }
            });
        }
    }, [jobRuns, listToRefresh]);

    // UseEffect to refresh when component mounts
    useEffect(() => {
        refresh();
    }, [refresh]);

    function changePage(index: number): void {
        setCurrentTab(index);
    };

    return (
        <React.Fragment>
            <div className="runs-container">
                <div className={"runs-internal-container"}>
                    <div className={"runs-title-container"}>
                        <h2>{"Your 500 last runs"}</h2>
                    </div>
                    <RunGrid jobRuns={jobRuns} OnClick={onRunClick} />
                {/*    <Tabs width='100%' stylingMode='secondary' onSelectedIndexChange={changePage} selectedIndex={currentTab} >*/}
                {/*        <Item text="Grille">*/}
                {/*        </Item>*/}
                {/*        <Item text="Gantt">*/}
                {/*        </Item>*/}
                {/*    </Tabs>*/}
                {/*    {(currentTab === 0) ? <div><RunGrid jobRuns={jobRuns} /></div> : ""}*/}
                {/*    {(currentTab === 1) ? <div>Gantt</div> : ""}*/}
                </div>
            </div>
        </React.Fragment >
    );
}