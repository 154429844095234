import CustomerAgentListDto from "../customeragentmodule/CustomerAgentListDto";
import DataSourceTypeListDto from "./DataSourceTypeListDto";

export default class DataSourceFormDto {

    constructor(dataSourceType: DataSourceTypeListDto | null, dataSourceName: string | null, customerAgent: CustomerAgentListDto | null) {
        this.DataSourceType = dataSourceType;
        this.DataSourceName = dataSourceName;
        this.CustomerAgent = customerAgent;
    }

    DataSourceType: DataSourceTypeListDto | null;
    DataSourceName: string | null;
    CustomerAgent: CustomerAgentListDto | null;
}