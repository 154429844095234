import ProcessingResult from "../../framework/ProcessingResult";

import DataSourceApi from "../../api/DataSourceApi";

import GetTestDataSourceResult from "../../classes/logics/GetTestDataSourceResult";

export default class DataSourceCrudLogic {

    public CurrentDataSourceId: number | null;

    constructor() {
        this.CurrentDataSourceId = null;
    }

    CreateOrUpdateDataSourceAsync = async (name: string, dataSourceType: number, customerAgentId: number | null, connectorType: number, parameters: object): Promise<ProcessingResult<GetTestDataSourceResult>> => {

        if (this.CurrentDataSourceId === null) {
            var createDataSourceResult = await DataSourceApi.CreateDataSourceAsync(name, dataSourceType, customerAgentId, connectorType, parameters);
            if (createDataSourceResult.IsFailed()) {
                return ProcessingResult.Error("Error in CreateDataSourceAsync " + createDataSourceResult.ErrorMessage);
            }
            if (createDataSourceResult.Result.Ok) {
                this.CurrentDataSourceId = createDataSourceResult.Result.Id;
            }

            return ProcessingResult.Ok(new GetTestDataSourceResult(createDataSourceResult.Result.Ok, createDataSourceResult.Result.ErrorDetails));
        }

        var updateDataSourceResult = await DataSourceApi.UpdateDataSourceAsync(this.CurrentDataSourceId, name, dataSourceType, customerAgentId, connectorType, parameters);
        if (updateDataSourceResult.IsFailed()) {
            return ProcessingResult.Error("Error in UpdateDataSourceAsync " + updateDataSourceResult.ErrorMessage);
        }

        return ProcessingResult.Ok(new GetTestDataSourceResult(updateDataSourceResult.Result.Ok, updateDataSourceResult.Result.ErrorDetails));
    }
}