import React, { useRef, useImperativeHandle, forwardRef } from 'react';

import { DataChange } from 'devextreme/common/grids';

import FieldGrid, { FieldGridHandle } from './FieldGrid';

import PathSynchroMappingDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingDto';
import SynchroFieldMappingDto from '../../classes/dtos/pathsynchromodule/SynchroFieldMappingDto';

import TransformationCapabilitiesDto from '../../classes/dtos/transformationmodule/TransformationCapabilitiesDto';
import FieldReferenceIdAndKeyDto from '../../classes/dtos/FieldReferenceIdAndKeyDto';
import PathSynchroMappingCapabilitiesDto from '../../classes/dtos/pathsynchromodule/PathSynchroMappingCapabilitiesDto';

import './SynchroMappingFields.css';

interface SynchroMappingFieldsProps {
    Mappings: PathSynchroMappingDto[];
    MappingChanges: DataChange[] | undefined;

    SourceFields: FieldReferenceIdAndKeyDto[];
    TargetFields: FieldReferenceIdAndKeyDto[];
    Capabilities: PathSynchroMappingCapabilitiesDto[];
    TransformationsCapabilities: TransformationCapabilitiesDto[];
    Mapping: SynchroFieldMappingDto[];

    SetMappingChanges(changes: DataChange[], origin: SynchroFieldMappingDto[]): void;
}

export interface SynchroMappingFieldsHandle {
    Save: () => void;
}

const SynchroMappingFields: React.FC<SynchroMappingFieldsProps> = forwardRef<SynchroMappingFieldsHandle, SynchroMappingFieldsProps>(({ Mappings, MappingChanges, SetMappingChanges, SourceFields, TargetFields, Capabilities, TransformationsCapabilities, Mapping }, ref) => {

    useImperativeHandle(ref, () => ({
        Save() {
            if (fieldGridRef.current) {
                fieldGridRef.current.Save();
            }
        }
    }));

    const fieldGridRef = useRef<FieldGridHandle>(null);

    return (
        <React.Fragment>
            <div style={{ width: '100%', height: '100%', position: 'relative', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                <div style={{ top: 10, position: 'absolute', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', display: 'inline-flex' }}>
                    <FieldGrid
                        ref={fieldGridRef}
                        Mappings={Mappings}
                        MappingChanges={MappingChanges}
                        SetMappingChanges={SetMappingChanges}

                        SourceFields={SourceFields}
                        TargetFields={TargetFields}
                        Capabilities={Capabilities}
                        TransformationsCapabilities={TransformationsCapabilities}
                        Mapping={Mapping}
                    />
                </div>
            </div>
        </React.Fragment>
    );
});

export default SynchroMappingFields;