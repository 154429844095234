import React, { useState, useMemo, useRef, forwardRef, useImperativeHandle } from 'react';
import { ValidationRule } from 'devextreme-react/common';
import { Popup } from 'devextreme-react/popup';
import { Form, Label, FormRef, GroupItem, SimpleItem, ButtonItem } from 'devextreme-react/form';
import { ButtonType } from 'devextreme-react/common';
import { LoadPanel } from 'devextreme-react/load-panel';
import 'devextreme-react/text-area';

import LabelTemplate from './LabelTemplate';

import { ITableSelectionFormHandle, ITableSelectionFormProps } from './ITableSelectionForm';

import TableApi from "./../../api/TableApi";

import FieldReferenceLogic from '../../logics/datastructuremodule/FieldReferenceLogic';

import TableReferenceIdAndKeyDto from '../../classes/dtos/TableReferenceIdAndKeyDto';

import TableSelectionDto from '../../classes/dtos/datastructuremodule/TableSelectionDto';

import './TableSelectionForm.scss';

const TableSelectionListForm: React.FC<ITableSelectionFormProps> = forwardRef<ITableSelectionFormHandle, ITableSelectionFormProps>(({ DataSourceId, DatabaseId, SetTableSelected }, ref) => {

    const position = { of: '#root' };

    const [tableSelectionDto, setTableSelectionDto] = useState<TableSelectionDto>(new TableSelectionDto(null));

    const [isOpen, setIsOpen] = useState(false);

    const [refreshInProgress, setRefreshInProgress] = React.useState(false);

    useImperativeHandle(ref, () => ({
        Open(tableReferenceId: number, onlyUpdatable: boolean) {
            setIsOpen(true);

            TableApi.GetTablesAsync(DataSourceId, DatabaseId, false)
                .then(getTablesResult => {
                    var allTables = getTablesResult.Result.Tables
                        .toSorted((a, b) => (a.Name.toLowerCase() > b.Name.toLowerCase()) ? 1 : (a.Name.toLowerCase() < b.Name.toLowerCase()) ? -1 : 0);
                    if (onlyUpdatable) {
                        allTables = allTables.filter((e) => !e.ReadOnly);
                    }
                    let current = allTables.find((e) => e.Id === tableReferenceId) ?? null;
                    setTables(allTables);
                    setTableSelectionDto(new TableSelectionDto(current));
                });
        }
    }));

    const formRef = useRef<FormRef>(null);

    const [tables, setTables] = useState<TableReferenceIdAndKeyDto[] | null>(null);

    const tableEditorOptions = {
        items: tables,
        searchEnabled: true,
        displayExpr: "Name"
    };

    const validationRules: {
        table: ValidationRule[]
    } = {
        table: [
            { type: 'required', message: 'Table is required.' },
        ]
    };

    function cancel(): void {
        setIsOpen(false);
    };

    const popupAttributes = useMemo(() => {
        return {
            id: 'elementId',
            class: 'class-name-popup'
        }
    }, []);

    const handleSubmit = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        setRefreshInProgress(true);

        SetTableSelected(tableSelectionDto);

        if (tableSelectionDto.Table !== null) {
            var getFieldReferencesResult = await FieldReferenceLogic.GetFieldReferencesAsync(DataSourceId, tableSelectionDto.Table?.Id, true);
            if (getFieldReferencesResult.IsFailed()) {
                alert(getFieldReferencesResult.ErrorMessage);
            }
        }

        setRefreshInProgress(false);
        setIsOpen(false);
    };

    const cancelButtonOptions = {
        text: 'Cancel',
        type: 'outlined' as ButtonType,
        useSubmitBehavior: false,
        width: '100%',
        onClick: cancel
    };

    const confirmButtonOptions = {
        text: 'Confirm',
        type: 'default' as ButtonType,
        useSubmitBehavior: true,
        width: '100%'
    };

    return (
        <React.Fragment>
            <Popup
                wrapperAttr={popupAttributes}
                visible={isOpen}
                onHiding={cancel}
                dragEnabled={false}
                hideOnOutsideClick={false}
                showCloseButton={true}
                showTitle={true}
                title="Choose your table"
                container=".dx-viewport"
                width={600}
                height="auto"
            >
                <form onSubmit={handleSubmit}>
                    <Form
                        ref={formRef}
                        formData={tableSelectionDto}
                        showColonAfterLabel={true}
                        showValidationSummary={false}
                        validationGroup="TableSelectionList"
                        colCount={2}
                    >
                        <GroupItem colSpan={2}>
                            <SimpleItem dataField="Table" editorType="dxSelectBox" editorOptions={tableEditorOptions} validationRules={validationRules.table}>
                                <Label render={LabelTemplate('product')} />
                            </SimpleItem>
                        </GroupItem>
                        <GroupItem colSpan={2}>
                            <div style={{ width: '100%', height: 36, flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 12, display: 'inline-flex' }}>
                                <div style={{ justifyContent: 'flex-start', alignItems: 'center', gap: 4, display: 'inline-flex' }}>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                    <div style={{ color: '#9393A2', fontSize: 10, fontFamily: 'Manrope', fontWeight: '600', textTransform: 'uppercase', lineHeight: '13px', letterSpacing: 1, wordWrap: 'break-word' }}>OR</div>
                                    <div style={{ width: 20, height: 0, opacity: 0.50, border: '1px #5A5A65 solid' }}></div>
                                </div>
                            </div>
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={cancelButtonOptions} />
                        </GroupItem>
                        <GroupItem>
                            <ButtonItem buttonOptions={confirmButtonOptions} />
                        </GroupItem>
                    </Form>
                </form>
            </Popup>
            <LoadPanel
                position={position}
                shadingColor="rgba(0,0,0,0.4)"
                visible={refreshInProgress}
            />
        </React.Fragment>
    );
});

export default TableSelectionListForm;