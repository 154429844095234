class DataSourceDetailDto {
    public Id: number;
    public Name: string;
    public Type: number;
    public ConnectorType: number;
    public CustomerAgentId: number | null;
    public Parameters: object;

    constructor(
        id: number,
        name: string,
        type: number,
        connectorType: number,
        customerAgentId: number | null,
        parameters: object
    ) {
        this.Id = id;
        this.Name = name;
        this.Type = type;
        this.ConnectorType = connectorType;
        this.CustomerAgentId = customerAgentId;
        this.Parameters = parameters;
    }
}

export default DataSourceDetailDto;
