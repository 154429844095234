import PathType from "../../../consts/pathmodule/PathType";
import { IJobResumeDto } from "./IJobResumeDto";

export default class PathReportingJobResumeDto extends IJobResumeDto {
	RowCount: number;
	constructor(
		rowCount: number) {
		super(PathType.Reporting);
		this.RowCount = rowCount;
	}
}