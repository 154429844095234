class TableReferenceIdAndKeyDto {
    Id: number;
    Key: string;
    Name: string;
    Type: number;  // Assuming Type is a number where 1 is for 'Table' and 2 is for 'View'
    ReadOnly: boolean;
    InformativeRowCount: number | null;
    ParentId: number | null;

    constructor(id: number, key: string, name: string, type: number, readOnly: boolean, informativeRowCount: number | null, parentId: number | null) {
        this.Id = id;
        this.Key = key;
        this.Name = name;
        this.Type = type;
        this.ReadOnly = readOnly;
        this.InformativeRowCount = informativeRowCount;
        this.ParentId = parentId;
    }

    getLabel(): string {
        let typeLabel = "??";
        if (this.Type === 1) { typeLabel = "Table"; }
        else if (this.Type === 2) { typeLabel = "View"; }

        return `${this.Name} [${typeLabel}]${this.InformativeRowCount != null ? ` (${this.InformativeRowCount} rows)` : ""}`;
    }
}

export default TableReferenceIdAndKeyDto;
